import { createSlice } from '@reduxjs/toolkit';

const historySlice = createSlice({
    name: 'history',
    initialState: { history: [] } as any,
    reducers: {
        createHistory: (state, { payload }: any) => {
            const { currentRealmName, location } = payload;
            if (state.history.length === 0) {
                state.history = [{ pathname: `/${currentRealmName}` }, location];
            } else if (`/${currentRealmName}` === location.pathname) {
                state.history = [location];
            } else if (state.history[state.history.length - 1].pathname !== location.pathname) {
                state.history = [...state.history, location];
            } else {
                state.history.splice(state.history.length - 1, 1, location);
            }
        },
        removeHistory: (state, { payload: level = 1 }: any) => {
            state.history.splice(state.history.length - 1, level);
        },
        resetGroupFromPath: (state, { payload }: any) => {
            const { currentRealmName, location } = payload;
            state.history = [{ pathname: `/${currentRealmName}` }, location];
        },
    },
});

export const { createHistory, removeHistory, resetGroupFromPath }: any = historySlice.actions;

export default historySlice;
