import React from 'react';

import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers-pro';
import moment from 'moment';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import { useAppDispatch, useTypedSelector } from '@fiji/common/src/app/store';
import { setUpdateProperties } from '@fiji/common/src/features/deviceManagement/deviceSlice';
import { FormControl, InputLabel, Select } from '@mui/material';
import { useStyles } from 'pages/AddDevice/styles';
import { useTheme } from '@mui/styles';

export const DateUpdateModal = ({ apiData, type }: { apiData?: any; type?: string }): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const classes = useStyles(theme);
    const { propertiesPayload } = useTypedSelector((state) => state.device);
    const [openSelectBox, setOpenSelectBox] = React.useState(false);
    const handleToggleSelectBox = (): void => {
        setOpenSelectBox(!openSelectBox);
    };

    return (
        <FormControl variant={'filled'} className={classes.formControl} fullWidth>
            <InputLabel id={'select-Assign-to-Group'} required={false}>
                Date
            </InputLabel>
            <Select
                displayEmpty
                id="installdate"
                labelId="demo-customized-select-label"
                renderValue={(): any => (
                    <>
                        {propertiesPayload?.[type as any] ? (
                            moment(propertiesPayload?.[type as any])?.format('LL')
                        ) : (
                            <></>
                        )}
                    </>
                )}
                value={propertiesPayload?.[type as any]}
                open={openSelectBox}
                onClose={handleToggleSelectBox}
                onOpen={handleToggleSelectBox}
            >
                {openSelectBox && (
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateCalendar
                            {...(type === 'batteryInstallDate' && {
                                minDate: moment(
                                    apiData?.find((item: any) => item?.key === 'deviceInstallDate')?.value +
                                        1000 * 60 * 60 * 24
                                ),
                                disableFuture: true,
                            })}
                            {...(type === 'deviceInstallDate' && {
                                maxDate: moment(
                                    apiData?.find((item: any) => item?.key === 'batteryInstallDate')?.value
                                ),
                            })}
                            value={moment(propertiesPayload?.[type as any]) ?? null}
                            onChange={(date): void => dispatch(setUpdateProperties({ key: type, value: date }))}
                        />
                    </LocalizationProvider>
                )}
            </Select>
        </FormControl>
    );
};
