import dayjs from 'dayjs';
import '../features/schedule/scheduleApi';

const isNullOrUndefined = (item: string | number | [] | object | undefined | null): boolean =>
    item === null || item === undefined;

function customEndpointSelector(type: string): Array<{ key: string; endpoint: string }> {
    switch (type) {
        case 'details':
        case 'properties':
        case 'settings':
        case 'gauge':
            return [{ key: type, endpoint: 'getChannelDetails' }];
        case 'device_list':
            return [{ key: type, endpoint: 'getAllDevices' }];
        case 'timeline':
            return [
                { key: type, endpoint: 'getDeviceTimeline' },
                { key: type, endpoint: 'getDeviceAlertCount' },
            ];
        case 'trends':
            return [{ key: type, endpoint: 'getDeviceTrends' }];
        case 'group_list':
            return [{ key: type, endpoint: 'getAllFlattenedGroups' }];
        case 'map':
            return [{ key: type, endpoint: 'deviceMap' }];
        case 'loads':
            return [{ key: type, endpoint: 'getMultiDeviceLoads' }];
        case 'virtual_loads':
            return [{ key: type, endpoint: 'getAllVirtualLoads' }];
        case 'upcoming':
            return [{ key: type, endpoint: 'getPredictionFilters' }];
        case 'score':
            return [{ key: type, endpoint: 'getScore' }];
        case 'components':
            return [{ key: type, endpoint: 'getComponentFilter' }];
        case 'schedule':
            return [{ key: type, endpoint: 'getAllSchedules' }];
        case 'command_bar':
            return [
                { key: type, endpoint: 'getChannelDetails' },
                { key: type, endpoint: 'getAvailableCommands' },
            ];
        default:
            return [{ key: type, endpoint: 'getChannelDetails' }];
    }
}

function getMqttTopiceByWidgetType(type: string): string {
    switch (type) {
        case 'trends':
            return 'BSSRM/TREND';
        case 'command_bar':
            return 'BSSRM/COMMAND_TOPIC';
        default:
            return 'BSSRM';
    }
}

function getWidgetTypeValidationConfig(type: string): { [key: string]: string | boolean | number } {
    const requiredConfig: { [x: string]: string | boolean | number } = {
        isSourceRequired: false,
        loaderType: 'progress',
    };

    switch (type) {
        case 'details':
            requiredConfig['primarySkeletonCount'] = 3;
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['isSourceRequired'] = true;
            break;
        case 'settings':
        case 'properties':
        case 'loads':
        case 'upcoming':
        case 'components':
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['isSourceRequired'] = true;
            break;
        case 'timeline':
        case 'schedule':
            requiredConfig['loaderType'] = 'skeleton';
            requiredConfig['primarySkeletonCount'] = 4;
            break;
        case 'group_list':
        case 'device_list':
        case 'virtual_loads':
            requiredConfig['loaderType'] = 'skeleton';
            break;
        case 'gauge':
        case 'charts':
        case 'trends':
        case 'command_bar':
            requiredConfig['isSourceRequired'] = true;
            break;
        default:
            break;
    }

    return requiredConfig;
}

function retrieveChannelTypefromWidgetType(widgetType?: string): string {
    switch (widgetType) {
        case 'details':
            return 'DETAIL';
        case 'settings':
            return 'SETTING';
        case 'properties':
            return 'PROPERTIES';
        case 'loads':
            return 'LOAD';
        case 'trends':
        case 'gauge':
            return 'TREND';
        case 'command_bar':
            return 'CONTROL';
        default:
            return 'DETAIL';
    }
}

function isWidgetApplicable(widgetData: any, dropType: any): boolean {
    switch (widgetData?.widgetType?.id) {
        case 'device_list':
            if (!widgetData?.config?.multiDevice && dropType === 'DEVICE') {
                return false;
            }
            return true;

        case 'group_list':
            if (!widgetData?.config?.multiDevice && dropType === 'DEVICE') {
                return false;
            }
            return true;

        case 'charts':
        case 'details':
        case 'settings':
        case 'properties':
        case 'loads':
        case 'gauge':
        case 'command_bar':
            if (!widgetData?.config?.multiDevice && dropType === 'GROUP') {
                return false;
            }
            return true;
        case 'trends':
            if (!widgetData?.config?.config && !widgetData?.config?.multiDevice && dropType === 'GROUP') {
                return false;
            }
            if (widgetData?.config?.config && !widgetData?.config?.config?.multiDevice && dropType === 'GROUP') {
                return false;
            }
            return true;
        default:
            return true;
    }
}

function isWidgetConfigured(widgetData: any): boolean {
    switch (widgetData?.widgetType?.id) {
        case 'details':
            if (!widgetData?.config?.secondary?.length && !widgetData?.config?.primary?.length) {
                return false;
            }
            return true;
        case 'properties':
        case 'settings':
        case 'command_bar':
        case 'charts':
            if (!widgetData?.config?.secondary?.length) {
                return false;
            }
            return true;
        case 'trends':
            if (widgetData?.config?.config && !widgetData?.config?.config?.channelData?.length) {
                return false;
            }
            if (!widgetData?.config?.config && !widgetData?.config?.channelData?.length) {
                return false;
            }
            return true;
        case 'loads':
            if (
                (!widgetData?.config?.multiDevice && !widgetData?.config?.secondary?.length) ||
                (widgetData?.config?.multiDevice && !widgetData?.config?.selectedNodes?.length)
            ) {
                return false;
            }
            return true;
        case 'group_list':
        case 'device_list':
        case 'timeline':
        case 'schedule':
        case 'map':
            if (widgetData?.config?.multiDevice && !Object.keys(widgetData?.config?.filters)?.length) {
                return false;
            }
            return true;
        default:
            return true;
    }
}

function getRandomColor(): string {
    const letters: any = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

const formatTime = (timeData: any): string => {
    let timeStr = '';
    if (timeData?.hours > 0) {
        timeStr += `${timeData?.hours}h`;
    }
    if (timeData?.minutes > 0 || timeData?.hours > 0) {
        timeStr += timeData?.minutes < 10 ? `0${timeData?.minutes}m` : `${timeData?.minutes}m`;
    }
    return (timeStr += timeData?.seconds < 10 ? `0${timeData?.seconds}s` : `${timeData?.seconds}s`);
};
const getTimeInFromTo = (value: number, key: string): any => {
    const current: number = Date.now();
    let newValue = 0;
    if (key === 'h') {
        newValue = value * 60 * 60 * 1000;
    }
    return {
        from: current - newValue,
        to: current,
    };
};

const getListItemBackgroundColor = (data: any, isDetails?: boolean): string => {
    if (
        data?.status === 'Active' &&
        (data?.severity === 'Alarm' || isDetails) &&
        data?.severity !== 'Offline' &&
        !data?.isAcknowledged
    ) {
        return '#CA3C3D';
    } else if (data?.status === 'Active' && data?.severity === 'Offline') {
        return '#69B1C3';
    } else if (data?.status === 'Active' && data?.isAcknowledged) {
        return '#E0F1FD';
    } else if (data?.severity === 'Alarm' && data?.isAcknowledged && data?.isCleared) {
        return '#D5D8DA';
    }
    return 'transparent';
};

const getListItemTextColor = (data: any): string => {
    if (data?.status === 'Cleared' || data?.severity === 'Cleared') {
        return '#424E54';
    } else if (data?.severity === 'Alarm' && data?.isAcknowledged) {
        return '#0088F2';
    } else if (data?.status === 'Active' && data?.severity === 'Offline') {
        return '#DBEEF2';
    }
    return '#FFFFFF';
};

const toCamelCaseAndCapitalize = (str: any): string => {
    const s =
        str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x: string) => x.slice(0, 1).toUpperCase() + x.slice(1).toLowerCase())
            .join('');
    return s.slice(0, 1).toUpperCase() + s.slice(1);
};

function getFromToFormat(type: string, selectedDate: any): any {
    if (type === 'week') {
        return {
            from: dayjs(selectedDate)?.clone()?.startOf('week').valueOf(),
            to: dayjs(selectedDate)?.clone()?.endOf('week').valueOf(),
        };
    }
    return {
        to: new Date(selectedDate).setHours(23, 59, 59, 0),
        from: new Date(selectedDate)?.setHours(0, 0, 0, 0),
    };
}

const getAlarmLabelValue = (data: any): string => {
    if (data?.severity === 'Offline' && data?.status === 'Active') {
        return data?.severity?.toLocaleUpperCase();
    } else if (data?.isAcknowledged && data?.status === 'Active') {
        return 'ACKNOWLEDGED';
    }
    return data?.isAcknowledged && data?.isCleared ? 'CLOSED' : data?.status?.toLocaleUpperCase?.();
};

const transformScheduleWidgetData = (scheduleWidgetData: any): any => {
    return scheduleWidgetData?.map((scheduleItem: any) => {
        return {
            id: scheduleItem.id,
            scheduleDate: new Date(scheduleItem?.schedules?.[0]).setHours(0, 0, 0, 0),
            schedules: [
                {
                    startTime: scheduleItem?.schedules?.[0],
                    ...(scheduleItem.actionEndTime && {
                        endTime: new Date(scheduleItem?.schedules?.[0]).setHours(
                            new Date(scheduleItem.actionEndTime).getHours(),
                            new Date(scheduleItem.actionEndTime).getMinutes(),
                            new Date(scheduleItem.actionEndTime).getSeconds()
                        ),
                    }),
                    name: scheduleItem?.name,
                    deviceCount: scheduleItem?.deviceCount,
                },
            ],
        };
    });
};

const CHART_TYPE: any = {
    bar: 'BAR_CHART',
    column: 'COLUMN_CHART',
    line: 'LINE_CHART',
    area: 'AREA_CHART',
};

export {
    getRandomColor,
    isNullOrUndefined,
    customEndpointSelector,
    getMqttTopiceByWidgetType,
    retrieveChannelTypefromWidgetType,
    getFromToFormat,
    formatTime,
    getTimeInFromTo,
    isWidgetConfigured,
    getListItemBackgroundColor,
    getListItemTextColor,
    toCamelCaseAndCapitalize,
    getAlarmLabelValue,
    getWidgetTypeValidationConfig,
    isWidgetApplicable,
    transformScheduleWidgetData,
    CHART_TYPE,
};
