import React from 'react';

import CustomIcon from '../../../../components/CustomIcon';

import { WidgetHeader } from 'pages/WidgetManagement/common/WidgetHeader';
import { InfoListItem } from '@brightlayer-ui/react-components';
import HelpCenter from '@mui/icons-material/HelpCenter';
import Workspaces from '@mui/icons-material/Workspaces';
import { Avatar, Divider, List, Paper, Stack, Typography } from '@mui/material';
import { VIEW_ALL_FOOTER, WidgetFooter } from 'pages/WidgetManagement/common';
import { CustomTransComponent, GroupPath } from 'components';
import { useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@fiji/common';
import { CustomEmptyState } from 'components/CustomEmptyState';
import { NotificationsActive, Warning } from '@mui/icons-material';
import { CloudOffFilled } from '@brightlayer-ui/icons-mui';

const COUNT_KEYS = ['alarmCount', 'warningCount', 'offlineCount'];

const Component = ({ widgetData: detailWidget, mode }: { widgetData: any; mode: string }): JSX.Element => {
    const currentRealmName = useTypedSelector((state) => state.common.selectedRealm);

    const colors: any = {
        warningCount: '#EEA31B',
        offlineCount: '#69B1C3',
        alarmCount: '#CA3C3D',
    };

    const navigate = useNavigate();
    const getIcon = (status: string): JSX.Element => {
        switch (status) {
            case 'alarmCount':
                return <NotificationsActive sx={{ color: colors[status] }} fontSize={'inherit'} />;
            case 'offlineCount':
                return <CloudOffFilled sx={{ color: colors[status] }} fontSize={'inherit'} />;
            case 'warningCount':
                return <Warning sx={{ color: colors[status] }} fontSize={'inherit'} />;
            default:
                return <></>;
        }
    };

    const getIconData = (count: number, status: string): JSX.Element =>
        count ? (
            <Stack key={status} flexDirection={'row'} alignItems={'center'} sx={{ mr: 2 }}>
                {getIcon(status)}
                <Typography
                    sx={{
                        color: colors[status],
                        fontSize: '14px',
                        fontWeight: '600',
                    }}
                >
                    {count}
                </Typography>
            </Stack>
        ) : (
            <></>
        );
    return (
        <Paper sx={{ height: '100%' }}>
            <WidgetHeader
                mode={mode}
                widgetData={detailWidget}
                count={detailWidget?.multiDevice ? detailWidget?.filters?.groupIds?.length : detailWidget?.total}
            />
            {mode !== 'edit' && <Divider />}
            <Stack
                sx={{
                    height: `calc(100% - ${mode === 'edit' || detailWidget?.viewAllButton ? '97px' : '49px'})`,
                    overflow: 'auto',
                }}
            >
                {detailWidget?.secondary?.length ? (
                    detailWidget?.secondary
                        ?.slice(0, detailWidget?.maxCount ?? detailWidget?.secondary?.length)
                        .map((item: any) => (
                            <List key={item?.id} disablePadding>
                                <InfoListItem
                                    title={''}
                                    divider={'full'}
                                    avatar
                                    iconAlign="center"
                                    statusColor={item?.indicator}
                                    leftComponent={
                                        <>
                                            <Stack className="white-space-pre">
                                                <Typography
                                                    sx={{ fontSize: '16px', fontWeight: '600', color: '#424E54' }}
                                                >
                                                    {item?.name}
                                                </Typography>
                                                <GroupPath pathName={item?.groupPath} width={'220px'} />
                                            </Stack>
                                        </>
                                    }
                                    icon={
                                        <Avatar sx={{ bgcolor: item?.icon?.web?.style?.backgroundColor }}>
                                            <CustomIcon
                                                iconName={item.icon?.web?.name ?? ''}
                                                family={item.icon?.web?.family ?? ''}
                                                iconProps={{ sx: { color: item?.icon?.web?.style?.color } }}
                                                defaultIcon={<HelpCenter color="primary" />}
                                            />
                                        </Avatar>
                                    }
                                    rightComponent={
                                        <Stack flexDirection={'column'} alignItems={'center'}>
                                            <Stack flexDirection={'row'} alignItems={'center'}>
                                                {COUNT_KEYS?.map((subItem: string) =>
                                                    getIconData(item[subItem], subItem)
                                                )}
                                            </Stack>
                                            {item['deviceCount'] ? (
                                                <Typography
                                                    sx={{
                                                        marginTop: '8px',
                                                        fontSize: '14px',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    {`${item['deviceCount']} ${
                                                        item['deviceCount'] > 1 ? 'Devices' : 'Device'
                                                    } `}
                                                </Typography>
                                            ) : (
                                                <></>
                                            )}
                                        </Stack>
                                    }
                                    chevron
                                    ripple
                                    onClick={(): void => {
                                        if (mode === 'view') {
                                            navigate(`/${currentRealmName}/groupDetails/${item.id}`);
                                        }
                                    }}
                                />
                            </List>
                        ))
                ) : (
                    <CustomEmptyState
                        className="padding-y-96"
                        icon={<Workspaces fontSize={'inherit'} />}
                        title={<CustomTransComponent translationKey={'GROUP_MANAGEMENT:LABELS.NO_GROUPS_FOUND'} />}
                    />
                )}
            </Stack>
            <WidgetFooter
                actionBtnName={`View All ${VIEW_ALL_FOOTER['group_list']}`}
                mode={mode}
                widgetData={detailWidget}
                disabled={!detailWidget?.secondary?.length}
            />
        </Paper>
    );
};
export default Component;
